import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import MapContainer from './showcase/components/MapComponent'
// import { DemoApp } from './showcase/newtemplate'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import AppContainer from './panel/containers/app/AppContainer'

const LazyMapContainer = lazy(() => import('./showcase/components/MapComponent'));
const LazyDemoApp = lazy(() => import('./showcase/newtemplate'));
const LazyPanel = lazy(() => import('./panel/containers/app/AppContainer'));
ReactDOM.render(
  <React.Fragment>
    <Router>
      <Suspense fallback={<div>Yükleniyor...</div>}>
        <Switch>
          <Route path="/showcase" component={LazyMapContainer}></Route>
          <Route path="/3dpanel" component={LazyPanel}></Route>
          <Route path="/" component={LazyDemoApp}></Route>
          <Route path="*" render={() => <div>BULUNAMADI</div>}></Route>
        </Switch>
      </Suspense>
    </Router>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
